

const initTipsDialog = () => {
  console.log('tip modal', document.querySelectorAll('.js-tip-item'));
  const tipsDialog = document.querySelector('.js-tips-modal');

  if(!tipsDialog) return;

  // const tips = document.querySelectorAll('.js-tip-item');
  const closeModal = document.querySelector('.js-close-tips-modal');
  
  const onClickTip = (clickedTip, ev) => {
    ev.preventDefault();
    const template = document.querySelector(clickedTip.dataset.template);
    tipsDialog.querySelector('.js-tips-modal-content').innerHTML = template.innerHTML;
    tipsDialog.showModal();
  };

  document.addEventListener('click', e => {
    let currentNode = e.target;
    if(e.target && e.target.nodeName && e.target.nodeName.toLowerCase() == 'div') return false; // bail on traversing the document if we hit a div element
    while(true) { // search parents to find out if we've clicked a share link
      if(currentNode.matches('.js-tip-item')) {
        return onClickTip(currentNode, e);
      } else if(currentNode != e.currentTarget && currentNode != document.body && (currentNode.nodeName && currentNode.nodeName.toLowerCase() != 'div')) {
        currentNode = currentNode.parentNode;
      } else {
        return false;
      }
    }
  });

  closeModal.addEventListener('click', (ev) => {
    ev.preventDefault();
    tipsDialog.close();
  });
};

const initShareDialog = () => {
  const shareDialog = document.querySelector('.js-share-modal');

  if(!shareDialog) return;

  const shareButton = document.querySelector('.js-share-results-button');
  const closeModal = document.querySelector('.js-close-share-modal');

  shareButton.addEventListener('click', (ev) => {
    ev.preventDefault();
    shareDialog.showModal();
  });

  closeModal.addEventListener('click', (ev) => {
    ev.preventDefault();
    shareDialog.close();
  });
};

const modals = () => {
  initTipsDialog();
  initShareDialog();

  // const dialogs = document.querySelectorAll('dialog:not(.ready)');
  const dialogs = document.querySelectorAll('dialog');

  dialogs.forEach(_dialog => {
    _dialog.addEventListener('mousedown', event => {
      if (event.target === event.currentTarget) {
        event.currentTarget.close()
      }
    });
  });

  // dialogs.forEach(_dialog => _dialog.classList.add('ready'));
};

export default modals;
