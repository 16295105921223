// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import "@hotwired/turbo-rails";
import { Turbo } from "@hotwired/turbo-rails"

import Swiper from 'swiper';
import { Navigation, Scrollbar } from 'swiper/modules';
import carbonFootprintChart from './modules/carbon-footprint-chart';
import lifeExpectancyChart from './modules/life-expectancy-chart';
import wellbeingChart from './modules/wellbeing-chart';
import CountryShapes from './modules/country-svg';
import modals from './modules/modals';
import initShareLinks from './modules/social-share';

function stripAndTruncate(htmlString, maxLength) {
  // Use a DOM parser to safely parse the HTML string and extract text
  var tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlString;
  var text = tempDiv.textContent || tempDiv.innerText || "";

  // Truncate the text to the specified maxLength
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  } else {
    return text;
  }
}

const loadtips = () => {
  const generateTurboStream = (data) => {
    // Construct the turbo-stream update message as a string
    let content = '';
    if(data.length) {
      content = `
        <div class="results-page__breakdown">
          <div class="content-wrapper">
            <h2>Tips and advice</h2>
            <div class="tips">
            ${data.map((tip, i) => (
              `
                <article class="tips-card">
                  <a href="#" title="" class="tips-card__link js-tip-item" data-template="#tips-item-${ i }">
                    <span class="title">${tip.title}</span>
                    <span class="summary">${tip.introduction || stripAndTruncate(tip.content, 104)}</span>
                    <span class="icon"><svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 7a1 1 0 000 2V7zm18.707 1.707a1 1 0 000-1.414L13.343.929a1 1 0 10-1.414 1.414L17.586 8l-5.657 5.657a1 1 0 001.414 1.414l6.364-6.364zM1 9h18V7H1v2z" fill="#C74039"/></svg></span>
                  </a>
                  <script id="tips-item-${ i }" type="text/template">
                    <h3>${tip.title}</h3>
                    <p>${tip.content}</p>
                  </script>
                </article>
              `
            )).join('')}
          </div>
          <div class="tips__footnote">
            This project is just beginning. If you know of any other cool campaigns or organisations that are working towards solutions for communities or your country as a whole to achieve sustainable wellbeing, let us know by emailing us on <a href="mailto:happyplanet@hotorcool.org">happyplanet@hotorcool.org</a>.
          </div>
        </div>
      `;
    }
    
    return `<turbo-stream action="replace" target="tips_frame">
      <template>${content}</template>
    </turbo-stream>`;
  }

  const fetchTips = ()  => {
    fetch('tips_advice.json')
      .then(response => response.json())
      .then(data => {
        // console.log('LOADING TIPS', data);
        // Handle the data, assuming the response is HTML
        Turbo.renderStreamMessage(generateTurboStream(data));
      });
  };

  fetchTips();
};

/** animate scrolling */
const easeOut = t => 1 - Math.pow(1 - t, 1.675); // eslint-disable-line no-restricted-properties

const scrollToElement = (
  startTime, currentTime, duration, scrollEndElemTop, startScrollOffset, container = null,
) => {
  const runtime = currentTime - startTime;
  let progress = runtime / duration;

  progress = Math.min(progress, 1); // eslint-disable-line no-restricted-properties

  const ease = easeOut(progress);

  (container || window).scroll(0, startScrollOffset + (scrollEndElemTop * ease));

  if (runtime < duration) {
    requestAnimationFrame((timestamp) => {
      const currentTime = timestamp || new Date().getTime();
      scrollToElement(
        startTime,
        currentTime,
        duration,
        scrollEndElemTop,
        startScrollOffset,
        container,
      );
    });
  }
};

const isElement = element => element instanceof Element || element instanceof HTMLDocument;

/**
  * smooth scroll to an anchor point
  * useage:
  *
  * scrollTo('anchorID', 1000);
  *
  */
export const scrollTo = (selector, time = 1200, offset = 0) => {
  const target = isElement(selector) ? selector : document.querySelector(selector);

  requestAnimationFrame((timestamp) => {
    const stamp = timestamp || new Date().getTime();
    const duration = time;
    const start = stamp;

    const startScrollOffset = window.pageYOffset;
    const scrollEndElemTop = target.getBoundingClientRect().top + offset;

    scrollToElement(start, stamp, duration, scrollEndElemTop, startScrollOffset);
  });
};

document.addEventListener("turbo:load", () => {
  CountryShapes.init();
  modals();
  initShareLinks();

  if(document.querySelector('#tips_frame')) {
    loadtips();
  }

  const carousel = document.querySelector('.js-answer-swiper');
  if(carousel) {
    const swiper = new Swiper(carousel, {
      modules: [Navigation, Scrollbar],
      speed: 300,
      loop: false,
      cssMode: false,
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 51,
      navigation: {
        nextEl: `.swiper-button-next`,
        prevEl: `.swiper-button-prev`,
      },
      scrollbar: {
        el: `.js-swiper-scrollbar`,
        draggable: true,
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
          slidesPerGroup: 2,
        },
        1024: {
          spaceBetween: 51,
          slidesPerView: 3,
          slidesPerGroup: 3,
        },
      },
    });
  }

  if(document.querySelector('.quiz-page')) {
    document.querySelector('.form__input')?.focus();
  }

  if(document.querySelector('.js-question-form')) {
    const form = document.querySelector('.js-question-form');


    const numberField = form.querySelector('.js-number-field');
    if(numberField) {
      numberField.addEventListener('input', (ev) => {
        const number = parseInt(numberField.value);

        if(number < 1907 || number > 2023) {
          numberField.setCustomValidity('Invalid year.');
        } else {
          numberField.setCustomValidity('');
        }
      });
    }

    form.addEventListener('change', ev => {
      const feedback = ev.currentTarget.querySelector('.question__feedback')
      if(feedback) {
        feedback.classList.toggle('show', true);
        scrollTo(feedback, 900, -300);
      }
    });
  }


  if(document.querySelector('.js-carbon-footprint-chart')) {
    carbonFootprintChart(
      document.querySelector('.js-carbon-footprint-chart')
    );
  }
  
  if(document.querySelector('.js-life-expectancy-chart')) {
    lifeExpectancyChart(
      document.querySelector('.js-life-expectancy-chart')
    );
  }
  
  if(document.querySelector('.js-wellbeing-chart')) {
    wellbeingChart(
      document.querySelector('.js-wellbeing-chart')
    );
  }
});